import React from "react"
import { Card, Button } from "react-bootstrap"
import "./blogCard.less"
import { IoIosArrowForward } from "react-icons/io"
import {Link} from "gatsby"
const BlogCard = props => {
  return (
    <div>
      <Card className="blog-card">
        <Card.Img variant="top" src={props.imgUrl} />
        <div className="date">{props.date}</div>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>{props.text}</Card.Text>
          <Link to={`/article/${props.id}`}>
          <Button className="read-more">
              Read More <IoIosArrowForward />
            </Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  )
}

export default BlogCard

import React, { useState } from "react"
import NavigationBar from "../components/navbar/navigationBar"
import Footer from "../components/footer/footer"
import { Col, Container, Form, Row } from "react-bootstrap"
import BlogCard from "../components/blog/blogCard"
import "./blogPage.less"
import { graphql } from "gatsby"
import SEO from "../components/head/seo"
const BlogPage = props => {
  const {data}=props;
  const [blogs,setBlogs]=useState(data.allStrapiArticles.edges);

  const handleSearch=(event)=>{
    const word=event.target.value?.toUpperCase();
    console.log(word)
    const filtered=data.allStrapiArticles.edges?.filter((document,index)=>{
      const title=document.node.Title.toUpperCase();
      console.log(title,title.includes(word))
      return title.includes(word);
    });
    console.log(filtered);
    setBlogs(filtered);
  }
    
  return (
    <>
    <SEO/>
    <div className="blog-page-container">
      <NavigationBar />
      <div className="bg-image"></div>
      <Container>
        <Row>
          <Col md={4}>
            <h1 className="display-2">{data.strapiBlogpage.Header}</h1>
            <h5>{data.strapiBlogpage.Description}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Form>
              <Form.Group>
                <Form.Control onChange={handleSearch} placeholder="&#x1F50D; Search"></Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row>
          <div className="blog-cards">
            {blogs.map((document, index) => {
              return (
                <BlogCard
                  id={document.node.id}
                  key={index}
                  title={document.node.Title}
                  text={document.node.ArticleBody}
                  date={document.node.published_at}
                  imgUrl={document.node.Image.localFile.publicURL}
                />
              )
            })}
          </div>
        </Row>
      </Container>
      <Footer />
    </div>
    </>
  )
}

export default BlogPage

export const query = graphql`
  query BlogsQuery {
    allStrapiArticles {
      edges {
        node {
          id
          Title
          ArticleBody
          published_at(formatString: "DD.MM.YYYY")
          Image {
            localFile{
            publicURL
            }
          }
          creator {
            username
          }
        }
      }
    }
    strapiBlogpage {
      Header
      Description
    }
  }
`
